import './App.css';
import pako from "pako";
import React, { useEffect, useState } from "react";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { OrbitProgress } from "react-loading-indicators";
import { v5 as uuidv5 } from 'uuid';

function toJson(data) {
  var unencoded = data.replaceAll("_", "+").replaceAll("-", "/")
  var jsonData = base64_decode(unencoded).split("").map((char) => char.charCodeAt(0))
  const decodedData = pako.inflate(jsonData, { to: "string" });
  return JSON.parse(decodedData)
}

function tableItem(title, value) {
  if (value == null || value == "") {
    return (<div></div>)
  }

  return (
    <tr>
      <td className='left'><b>{title}:</b></td>
      <td className='right'>{value}</td>
    </tr>
  )
}

function formItem(title, value) {
  if (value == null || value == "") {
    return (<div></div>)
  }

  return (
    <tr>
      <td className='left'><b>{title}:</b></td>
      <td className='right'>{value}</td>
    </tr>
  )
}

function listItem(value) {
  return (
    <tr>
      <td className='left'><b>&#8226;</b></td>
      <td className='right'>{value}</td>
    </tr>
  )
}

function ptDetailsSection(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div>
      <h2>Patient Details</h2>
      <br />
      <table id='detailsTable'>
        <tbody>
          {tableItem("Name", data.p.n)}
          {tableItem("Identity Number", data.p.i)}
          {tableItem("Address", data.p.a)}
          {tableItem("Email", data.p.e)}
          {tableItem("Phone Number", data.p.p)}
          {tableItem("Medical Aid", data.p.m)}
        </tbody>
      </table>
    </div>
  )
}

function doctorDetailsSection(data) {
  if (data.d == null) {
    return (<div></div>)
  }

  return (
    <div>
      <h2>Doctor Details</h2>
      <br />
      <table id='detailsTable'>
        <tbody>
          {tableItem("Name", data.d.f)}
          {tableItem("Qualification", data.d.q)}
          {tableItem("Registration Number", data.d.r)}
          {tableItem("Practice Number", data.d.pn)}
          {tableItem("Practice Name", data.d.n)}
          {tableItem("Email", data.d.e)}
          {tableItem("Phone", data.d.p)}
          {tableItem("Address", data.d.a)}
          {tableItem("Extras", data.d.ex)}
        </tbody>
      </table>
    </div>
  )
}

function buildMedications(data) {
  if (data.m == null) {
    return (<div></div>)
  }

  return (
    <div>
      <h2>Medications</h2>
      <br />
      <table>
        <tbody>
          {data.m.map(element => {
            return listItem(element);
          })}
        </tbody>
      </table>
    </div>
  )
}

function buildFulfilmentItem(item) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  var date = new Date(item.date)
  return (
    <tr>
      <td className='fillings'>
        {date.toLocaleDateString(undefined, options)}
      </td>
      <td className='fillings'>
        {item.pharmacyName}
      </td>
      <td className='fillings'>
        {item.notes}
      </td>
    </tr>
  )
}

function buildFulFillments(scriptFulfillments) {
  return (
    <table>
      <tbody>
        <tr>
          <th>
            Date Filled
          </th>
          <th>
            Pharmacy
          </th>
          <th>
            Notes
          </th>
        </tr>
        {scriptFulfillments.map(element => {
          return buildFulfilmentItem(element);
        })}
      </tbody>
    </table>
  )
}

function buildFulfillment(data, scriptFulfillments, setAddingFilling) {
  return (
    <div>
      <div>
        <h2>Filling History</h2>
        {scriptFulfillments == null ? <OrbitProgress color="#32cd32" size="medium" text="" textColor="" /> :
          <div>
            {scriptFulfillments.length == 0 && <h3> This script has not been filled </h3>}
            <button onClick={() => setAddingFilling(true)}> Mark as filled </button>
          </div>
        }

      </div>

      <br />
      {scriptFulfillments != null && scriptFulfillments.length != 0 && buildFulFillments(scriptFulfillments)}
    </div>
  )
}

function buildScript(data, scriptFulfillments, setAddingFilling, id) {
  if (data.p == null) {
    return (<div></div>)
  }
  return (
    <div id='body'>
      <div>
        <h1>Script for {data.p.n}</h1>
        <h3>Issued on: {data.t}</h3>
        <h3>Repeats: {data.r}</h3>
        {data.ph && data.ph.length != 0 && <h3>Pharmacy: {data.ph}</h3>}
        <br />
        {id && buildFulfillment(data, scriptFulfillments, setAddingFilling)}
        <br />
        {doctorDetailsSection(data)}
        <br />
        {ptDetailsSection(data)}
        <br />
        {buildMedications(data)}
      </div>
    </div>
  )
}

function buildSickNote(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div id='body'>
      <div>
        <h1>Sick Note for {data.p.n}</h1>
        <h3>Issued on: {data.t}</h3>
        {data.r && <h3>Days Off: {data.r}</h3>}
        {data.rd && <h3>Return to work: {data.rd}</h3>}
        <br />
        {doctorDetailsSection(data)}
        {ptDetailsSection(data)}
        {data.n != "" ? <h2>Notes</h2> : <div></div>}
        <p>{data.n}</p>
      </div>
    </div>
  )
}

function buildCertificate(data) {
  if (data.p == null) {
    return (<div></div>)
  }

  return (
    <div id='body'>
      <div>
        <h1>Medical Certificate for {data.p.n}</h1>
        <h3>Issued on: {data.t}</h3>
        <br />
        {doctorDetailsSection(data)}
        {ptDetailsSection(data)}
        {data.n != "" ? <h2>Notes</h2> : <div></div>}
        <p>{data.n}</p>
      </div>
    </div>
  )
}

function buildBody(type, data, scriptFulfillments, setAddingFilling, id) {
  switch (type) {
    case "p":
      return buildScript(data, scriptFulfillments, setAddingFilling, id);
    case "s":
      return buildSickNote(data);
    default:
      return buildCertificate(data);
  }
}

function buildAddFillingForm(setAddingFilling, setScriptFulfilments, formValues, setFormValues, id) {

  const handleSubmit = () => {
    if (formValues.name == null || formValues.name == "") {
      alert("Please fill in the pharmacy name")
      return;
    }
    setAddingFilling(false)
    setScriptFulfilments(null)
    fetch(`https://addscriptfulfillment-ogemri3jkq-uc.a.run.app`, {
      method: "post",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "scriptId": id,
        "pharmacyName": formValues.name,
        "notes": formValues.notes ?? ""
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setScriptFulfilments(data);
        setFormValues({})
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    <div>
      <table>
        <tbody>
          {formItem("Pharmacy Name", <input type='text' value={formValues.name} onChange={(e) => { setFormValues({ ...formValues, name: e.target.value }) }} />)}
          {formItem("Notes", <input type='textArea' value={formValues.notes} onChange={(e) => { setFormValues({ ...formValues, notes: e.target.value }) }} />)}
        </tbody>
      </table>
      <button style={{ "marginRight": "10px" }} onClick={() => handleSubmit()}>Submit</button>
      <button onClick={() => setAddingFilling(false)}>Cancel</button>
    </div>
  )
}

function App() {
  const [data, setData] = useState({});
  const [type, setType] = useState("p")
  const [id, setId] = useState(null)
  const [scriptFulfillments, setScriptFulfilments] = useState(null)
  const [isAddingFilling, setAddingFilling] = useState(false)
  const [formValues, setFormValues] = useState({})
  useEffect(() => {
    if (id == null) {
      return
    }
    fetch(`https://getscriptfulfillments-ogemri3jkq-uc.a.run.app?scriptId=${id}`)

      .then((res) => res.json())
      .then((data) => {
        setScriptFulfilments(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  useEffect(() => {

    const urlSearchString = window.location.search;

    const params = new URLSearchParams(urlSearchString);
    setData(toJson(params.get('d')))
    setType(params.get('t'))
    var idValue = params.get("id")
    if (idValue == null) {
      idValue = uuidv5(params.get('d'), uuidv5.URL).toString()
    }
    setId(idValue)
  }, []);

  return (
    <div className="body">
      {isAddingFilling ? buildAddFillingForm(setAddingFilling, setScriptFulfilments, formValues, setFormValues, id) : buildBody(type, data, scriptFulfillments, setAddingFilling, id)}
    </div>
  );
}

export default App;
